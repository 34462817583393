import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../../layouts'
import SEO from '../../components/SEO'
import Article from '../../components/Article'
import Container from '../../components/Container'
import Disqus from '../../components/Disqus'
import './page.scss'

class PageTemplate extends React.Component {
  render () {
    const page = this.props.data.mdx

    return (
      <Layout
        location={this.props.location}
        siteMeta={this.props.data.site.siteMetadata}
      >
        <SEO
          title={page.frontmatter.title}
          description={page.frontmatter.description || page.excerpt}
          image={
            page.frontmatter.image
              ? page.frontmatter.image.publicURL
              : null
          }
          pathname={page.fields.slug}
          article={true}
        />
        <main className="page-main-content">
          <Container>
            <div className="single-content">
              <Article
                post={page}
                siteMeta={this.props.data.site.siteMetadata}
              />
              <Disqus
                location={this.props.location}
                disqusShortname={
                  this.props.data.site.siteMetadata.disqusShortname
                }
              />
            </div>
          </Container>
        </main>
      </Layout>
    )
  }
}

export default PageTemplate

export const pageQuery = graphql`
  query PageBySlug($slug: String!) {
    site {
      siteMetadata {
        name
        title
        description
        author
        disqusShortname
        mailchimpEndpoint
      }
    }
    mdx(fields: { slug: { eq: $slug } }) {
      id
      excerpt
      timeToRead
      body
      fields {
        slug
      }
      frontmatter {
        title
        description
        date(formatString: "MMMM DD, YYYY")
        image {
          publicURL
          childImageSharp {
            gatsbyImageData(width: 960)
          }
        }
      }
    }
  }
`
